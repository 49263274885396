/*
  Plainpad - Self Hosted Note Taking App

  Copyright (C) 2020 Alex Tselegidis - https://alextselegidis.com

  This program is free software: you can redistribute it and/or modify
  it under the terms of the GNU General Public License as published by
  the Free Software Foundation, either version 3 of the License, or
  (at your option) any later version.

  This program is distributed in the hope that it will be useful,
  but WITHOUT ANY WARRANTY; without even the implied warranty of
  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  GNU General Public License for more details.

  You should have received a copy of the GNU General Public License
  along with this program.  If not, see <https://www.gnu.org/licenses/>
*/

export default {
  user: 'Användare',
  users: 'Användare',
  name: 'Namn',
  email: 'E-post',
  password: 'Lösenord',
  passwordConfirmation: 'Upprepa lösenord',
  admin: 'Administratör',
  role: 'Roll',
  actions: 'Kommandon',
  add: 'Lägg till',
  edit: 'Redigera',
  save: 'Spara',
  saveSuccess: 'Användaren har sparats.',
  saveFailure: 'Användaren kunde inte sparas.',
  delete: 'Radera',
  deletePrompt: 'Är du säker på att du vill radera användaren?',
  deleteSuccess: 'Användaren raderades.',
  deleteFailure: 'Användaren kunde inte raderas.',
  cancel: 'Avbryt',
  search: 'Sök',
  emailAlreadyInUse: 'E-postadressen används redan, vänligen ange en annan e-postadress.',
  passwordsMismatch: 'Lösenorden matchar inte, vänligen försök igen.',
  listFailure: 'Det gick inte att visa användare, vänligen försök igen.',
  filterUsers: 'Filtrera användare',
}
